
export default {
  data() {
    return {

      form : {
        id : '',
        nama_alat : '',
        jenis_sarana : '',
        kondisi_sarana: '',
        jenis_alat: '',
        sumber_alat: '',
        status_alat: '',
        tahun_alat: '',
        file : null,
          file_old : null,
      },

      
      list_data : [],

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,
      file_path: this.$store.state.url.URL_APP + "uploads/",
  

      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_lihat: false
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_Peralatan + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
      });
    },


    addData : function() {
        var formData = new FormData();
        formData.append('data', JSON.stringify(this.form))
        formData.append('file', this.form.file)
  
  
        fetch(this.$store.state.url.URL_Peralatan + "addData", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
      },


      editData : function(){
        var formData = new FormData();
        formData.append('data', JSON.stringify(this.form))
        formData.append('file', this.form.file)
        fetch(this.$store.state.url.URL_Peralatan + "editData", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(a, b){
        // console.log(a);
        // console.log(b);
        fetch(this.$store.state.url.URL_Peralatan + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({id : a, file : b})
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },


    selectData : function(data){
        this.form.id = data.id;
        this.form.nama_alat = data.nama_alat;
        this.form.jenis_sarana = data.jenis_sarana;
        this.form.kondisi_sarana = data.kondisi_sarana;
        this.form.jenis_alat = data.jenis_alat;
        this.form.sumber_alat = data.sumber_alat;
        this.form.status_alat = data.status_alat;
        this.form.tahun_alat = data.tahun_alat;


        this.form.file = data.foto_alat;
        this.form.createAt = data.createAt;
        this.form.file_old =  data.foto_alat;
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        


    // ====================================== PAGINATE ====================================

        onFileSelected: function(event) {
          this.form.file = event.target.files[0];
        },





  },

  mounted () {
    this.getView();
  },
}
